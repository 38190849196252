@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

code {
  font-family: "Inter", sans-serif !important;
}
.fm_inter {
  font-family: "Inter", sans-serif !important;
}
.focus_outline__none:focus-visible {
  outline: none;
}

/* FONT WEIGHTS */
.fw_100 {
  font-weight: 100;
}
.fw_200 {
  font-weight: 200;
}

.fw_300 {
  font-weight: 300;
}
.fw_400 {
  font-weight: 400;
}
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}
.fw_700 {
  font-weight: 700;
}
.fw_800 {
  font-weight: 800;
}
.fw_900 {
  font-weight: 900;
}

@media print {
  .print-header {
    display: block; /* Ensure header displays only once */
  }

  .print-footer {
    display: table-footer-group;
  }

  table,
  tr,
  th,
  td {
    page-break-inside: avoid;
  }

  @page {
    margin: 10mm;
  }
}
/* globals.css */
:root {
  --primary: #277dfe; /* This should be the RGB values (not HSL) for Tailwind to interpret */
  --secondary: #0d111a; /* general text color */

  --success-color: #027a48; /* success color */
  --kebab-selection-color: #c4eab7; /* kebab selection color */

  --row-selection-color: #d5e6ff; /* row selection color */
  --kebab-plus-button: #aecaff; /* kebab plus button */
  --button-hover-color: #ed6f2480; /* button hover color */

  --error-color: #f51d00; /* error color */
  --remove-color: #f94b4b; /* remove color, e.g., disable group */
  --edit-color: #ffba08; /* edit color for disable group */

  --popup-overlay-color: black; /* popup overlay color */
  --heading-color: #000000; /* heading color */
  --sidebar-selected-color: #212d46; /* sidebar and pagination selected color */

  --description-color: #787878; /* description text */
  --button-border-color: #d0d5dd; /* button border, e.g., plus, popover table */
  --table-heading-color: #ffff; /* table heading color */
  --nav-heading-color: #475467; /* table heading color */
  --foot-tab-head-color: #475467; /* table heading color */
  --pageination-color: #475467; /* table heading color */
  --placeholder-color: #667085; /* placeholder text */
  --status-draft-open-color: #344054; /* draft or open status color */

  --table-heading-background: #277dfe; /* table heading background */
  --nav-head-bg: #f2f4f5; /* table heading background */
  --foot-tab-head-bg: #f2f4f5; /* table heading background */
  --tertiary-color: #ffffff; /* tertiary color (white) */
  --row-hover-color: #f3f8ff; /* row hover color */
  --toggle-disabled-background: #f4f4f4; /* toggle plus button disabled background */
  --light-border-color: #eaecf0; /* light border, e.g., kebab dropdown border */
  --disabled-color: #e0e0e0; /* light border, e.g., kebab dropdown border */

  --label-color: #0d111a; /* light border, e.g., kebab dropdown border */
  --value-color: #0d111a; /* light border, e.g., kebab dropdown border */
  --placeholder-color: #667085; /* light border, e.g., kebab dropdown border */
  --focus-color: #277dfe;
  --success-color-opacity: rgba(
    2,
    122,
    72,
    0.15
  ); /* 15% opacity success color */
  --danger-color-opacity: rgba(245, 29, 0, 0.15); /* 15% opacity danger color */

  /*================================== NEW VARIABLES FOR FS, COLOR, FW, SIZES =========================================================== */
  /* -----------------------------COMMON COLORS BETWEEN COMPONENTS START-------------------------------- */
  --error-color: #dc2626;
  --success-color: #047857;
  --draft-color: #344054;
  /* --success-color : , */
  /* -----------------------------COMMON COLORS BETWEEN COMPONENTS END-------------------------------- */
  /* ======================================================================================================================= */
  /* ----------------------------TOP NAV STYLING START------------- */
  --top-nav-heading-size: 16px;
  --top-nav-heading-weight: 600;
  --top-nav-heading-color: #000000;
  --top-nav-text-size: 14px;
  --top-nav-text-weight: 500;
  --top-nav-text-color: #787878;
  --top-nav-border-color: #e4e4e7;
  /* ----------------------------TOP NAV STYLING END------------------- */
  /* ======================================================================================================================= */
  /*----------------------------- LISTING TABLE PAGE------------------- */
  /* HEADER SECTION STYLING START----- */
  --primary-button-bg-color: #277dfe;
  --primary-button-hover-color: #2563eb;
  --primary-button-fg-shortcut-color: #2563eb;
  --primary-button-fg-color: #ffffff;
  --primary-button-font-weight: 500;
  --primary-button-font-size: 12px;

  --header-button-bg-color: #ffffff;
  --header-button-border-color: #e4e4e7;
  --header-button-hover-color: #e5e7eb;
  --header-button-fg-color: #020203;
  --header-button-disabled-fg-color: #a1a1aa;
  --header-button-disabled-bg-color: #e5e7eb;
  --head-button-font-size: 12px;
  --head-button-font-weight: 500;

  --head-input-bg-color: #ffffff;
  --head-input-fg-color: #374151;
  --head-input-border-color: #d1d5db;
  --head-input-focus-border-color: #277dfe;
  --head-input-placeholder-color: #9ca3af;
  --head-input-font-size: 12px;
  --head-input-font-weight: 500;
  --head-input-place-font-weight: 400;
  /* HEADER SECTION STYLING END----- */

  /* TABLE SECTION STYLING START----- */
  --list-table-border-color: #e4e4e7;
  --list-table-heading-bg: #dbeafe;
  --list-table-heading-fg-color: #71717a;
  --list-table-heading-fg-op-50-color: #71717a80;
  --list-table-row-hover-bg: #f3f7fa;
  --list-table-row-bg: #ffffff;
  --list-table-row-fg-color: #09090b;
  --list-table-row-border-color: #e4e4e7;
  --list-table-heading-font-size: 12px;
  --list-table-heading-font-weight: 600;
  --list-table-row-font-size: 12px;
  --list-table-row-badges-font-size: 10px;
  --list-table-row-icon-size: 14px;
  --list-table-row-font-weight: 400;
  /* TABLE SECTION STYLING END----- */

  /* TABLE PAGINATION STYLING START----- */
  --list-pagination-text: #6b7280;
  --list-pagination-button-bg: #f3f4f6;
  --list-pagination-button-hover-bg: #e5e7eb;
  --list-pagination-disabled-bg: #6b7280;
  --list-pagination-font-size: 12px;
  --list-pagination-font-weight: 400;
  --list-pagination-select-border: #d1d5db;
  --list-pagination-select-fg: #71717a;
  --list-pagination-select-bg: #ffffff;
  /* TABLE PAGINATION STYLING END----- */
  /* -------------------LIST TABLE STYLING------------------------ */
  /* ============================================================================================================================= */
  /* -------------------POPOVER STYLING START------------------------ */
  --popover-bg-color: #ffffff;
  --popover-border-color: #e4e4e7;
  --popover-fg-color: #09090b;
  --popover-hover-color: #f4f4f5;
  --popover-font-size: 12px;
  --popover-font-weight: 400;
  --popover-heading-fg-color: #09090b;
  --popover-heading-font-weight: 600;
  --popover-heading-font-size: 14px;
  --popover-shadow-color: #1018281a;
  /* -------------------POPOVER STYLING END------------------------ */
}

.border-radius {
  border-radius: 6px;
}

.underline_000000 {
  border-bottom: 1px solid #000000; /* You can adjust the color and style as needed */
}

.scrollbar-rounded::-webkit-scrollbar {
  width: 6px; /* Make the scrollbar even thinner */
  height: 6px; /* Thin scrollbar horizontally as well */
}

.scrollbar-rounded::-webkit-scrollbar-thumb {
  background-color: #c4bdbd; /* Color of the thumb */
  border-radius: 12px; /* Add rounded corners */
  cursor: pointer;
}

.scrollbar-rounded::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background color */
}

.new-section {
  page-break-before: always;
}

/* //Table check box */
/* Default Checkbox Styling */
.custom-checkbox {
  width: 14px;
  height: 14px;
  border: 2px solid #d1d5db; /* Gray-300 */
  border-radius: 4px;
  appearance: none; /* Hide default checkbox */
  cursor: pointer;
  position: relative;
}

/* Checked State */
.custom-checkbox:checked {
  background-color: #3b82f6; /* Blue-500 */
  border-color: #3b82f6;
}

/* Add a checkmark using pseudo-elements */
.custom-checkbox:checked::after {
  content: "✓";
  font-size: 12px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Disabled State */
.custom-checkbox:disabled {
  background-color: #e5e7eb; /* Gray-200 */
  border-color: #9ca3af; /* Gray-400 */
  cursor: not-allowed;
}

/* Checked & Disabled */
.custom-checkbox:checked:disabled {
  background-color: #93c5fd; /* Lighter Blue */
  border-color: #93c5fd;
  cursor: not-allowed;
}
