.tab-container {
  overflow-x: scroll;
  width: 100%;
  scrollbar-width: thin;
}
.tab-container::-webkit-scrollbar {
  height: 20px;
  width: 10px;
  position: fixed;
  bottom: 40rem;
  border-radius: 190px !important;
}

.tab-container::-webkit-scrollbar-thumb {
  background-color: #bdbcc6;
  border-radius: 6px;
}

.tab-container::-webkit-scrollbar-track {
  background-color: #ffff; /* Set background color */
  margin: 5px; /* Reduces track visibility */
}

.my-tab {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.tab-header {
  /* border-top: 1px solid var(--light-border-color); */
  border-bottom: 1px solid var(--light-border-color);
  padding: 8px 12px;
  text-align: left;
  z-index: auto;
}
.tab-cell {
  border-bottom: 1px solid var(--list-table-row-border-color);
  padding: 15px 10px;
  text-align: left;
}
.tab-header-right-not-fixed {
  border-top: 1px solid var(--list-table-row-border-color);
  border-bottom: 1px solid var(--list-table-row-border-color);
  padding: 12px 15px;
  text-align: left;
  /* z-index: auto; */
}
.tab-cell-right-not-fixed {
  border-bottom: 1px solid var(--list-table-row-border-color);
  padding: 15px 15px;
  text-align: left;
}

.tab-header:last-of-type {
  padding: 8px 12px;
  /* padding: 18px 10px; */
  text-align: left;
  /* position: sticky; */
  /* z-index: 0; Ensure it appears above the scrolling content */
  right: 0; /* Stick to the right side */
}

.tab-cell:last-of-type {
  /* background-color: #ffffff; */
  padding: 15px 10px;
  text-align: left;
  /* position: sticky; */
  /* z-index: 0; Ensure it appears above the scrolling content */
  right: 0; /* Stick to the right side */
}

.tab-header-past-orders {
  border-top: 1px solid #ebeaf2;
  border-bottom: 1px solid #ebeaf2;
  padding: 12px 15px;
  text-align: left;
}

.tab-cell-past-orders {
  border-bottom: 1px solid #ebeaf2;
  padding: 18px 15px;
  text-align: left;
}

.input_border {
  border: 1.55px solid #d0d5dd;
}

.table_search_input {
  color: #344054;
  font-weight: 600;
}
.table_search_input::placeholder {
  /* font-size: 12px; */
  color: #667085;
}
.table_search_input:focus-visible {
  outline: none;
}
.sap:checked::after {
  content: "\2713"; /* Unicode character for check mark */
  display: block;
  color: #7f56d9; /* Set the color for the tick mark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
